.MuiToolbar-root .MuiTablePagination-selectLabel {
  margin-bottom: 0;
}

.MuiToolbar-root .MuiTablePagination-displayedRows {
  margin-bottom: 0;
}
.css-i4bv87-MuiSvgIcon-root {
  margin-left: -20px !important;
}
.css-1ygcj2i-MuiTableCell-root {
  width: 20% !important;
}
.css-hbtnrh-MuiTableCell-root {
  width: 10% !important;
}
.css-1ex1afd-MuiTableCell-root {
  padding-left: 20px !important;
}
.css-i4bv87-MuiSvgIcon-root {
  padding-left: 10px !important ;
}

.css-1nu9jjj-MuiGrid-root {
  border-radius: 20px;
  padding: 40px !important;
}
.watermark-card {
  position: relative;
  overflow: hidden; /* Ensures that the watermark does not overflow the card boundaries */
}

.watermark-card::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px; /* Adjust size as needed */
  height: 150px; /* Adjust size as needed */
  background: url("../img/app_logo.png") no-repeat center center; /* Path to your logo */
  background-size: contain;
  opacity: 0.2; /* Adjust opacity as needed */
  transform: translate(-50%, -50%); /* Centers the watermark */
  z-index: 1; /* Ensure the watermark is behind the card content */
}
