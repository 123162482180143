#tab1,#tab2,#tab3,#tab4,#tab5,#tab6,#tab7,#tab8{
    border-color:#fff0;
    background:white;
    border-bottom: 2px solid white;
}
#tab1:hover,#tab2:hover,#tab3:hover,#tab4:hover,#tab5:hover,#tab6:hover,#tab7:hover,#tab8:hover{
    color: black;
    border-bottom: 2px solid black;
}
#scrollContainer {
    position: relative;
    overflow: hidden;
  }
  
  #navPane {
    display: flex;
    flex-wrap: nowrap;
    scroll-behavior: smooth;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch; 
  }
#navpanButtons{
    display: flex;
    flex-wrap: nowrap;
    z-index: 99;
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
}  
  #scrollLeft,#scrollRight {
    background-color: transparent;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    border: none;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  }
  