@font-face {
  font-family: 'simple-line-icons';
  src: url('Simple-Line-Icons.eot');
  src: url('Simple-Line-Icons.eot?v=2.4.0#iefix') format('embedded-opentype'), 
  url('Simple-Line-Icons.woff2') format('woff2'), 
  url('Simple-Line-Icons.ttf') format('truetype'), 
  url('Simple-Line-Icons.woff') format('woff'), 
  url('Simple-Line-Icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*
 Use the following CSS code if you want to have a class per icon.
 Instead of a list of all class selectors, you can use the generic [class*="icon-"] selector, but it's slower:
*/
.i-user,
.i-people,
.i-user-female,
.i-user-follow,
.i-user-following,
.i-user-unfollow,
.i-login,
.i-logout,
.i-emotsmile,
.i-phone,
.i-call-end,
.i-call-in,
.i-call-out,
.i-map,
.i-location-pin,
.i-direction,
.i-directions,
.i-compass,
.i-layers,
.i-menu,
.i-list,
.i-options-vertical,
.i-options,
.i-arrow-down,
.i-arrow-left,
.i-arrow-right,
.i-arrow-up,
.i-arrow-up-circle,
.i-arrow-left-circle,
.i-arrow-right-circle,
.i-arrow-down-circle,
.i-check,
.i-clock,
.i-plus,
.i-minus,
.i-close,
.i-event,
.i-exclamation,
.i-organization,
.i-trophy,
.i-screen-smartphone,
.i-screen-desktop,
.i-plane,
.i-notebook,
.i-mustache,
.i-mouse,
.i-magnet,
.i-energy,
.i-disc,
.i-cursor,
.i-cursor-move,
.i-crop,
.i-chemistry,
.i-speedometer,
.i-shield,
.i-screen-tablet,
.i-magic-wand,
.i-hourglass,
.i-graduation,
.i-ghost,
.i-game-controller,
.i-fire,
.i-eyeglass,
.i-envelope-open,
.i-envelope-letter,
.i-bell,
.i-badge,
.i-anchor,
.i-wallet,
.i-vector,
.i-speech,
.i-puzzle,
.i-printer,
.i-present,
.i-playlist,
.i-pin,
.i-picture,
.i-handbag,
.i-globe-alt,
.i-globe,
.i-folder-alt,
.i-folder,
.i-film,
.i-feed,
.i-drop,
.i-drawer,
.i-docs,
.i-doc,
.i-diamond,
.i-cup,
.i-calculator,
.i-bubbles,
.i-briefcase,
.i-book-open,
.i-basket-loaded,
.i-basket,
.i-bag,
.i-action-undo,
.i-action-redo,
.i-wrench,
.i-umbrella,
.i-trash,
.i-tag,
.i-support,
.i-frame,
.i-size-fullscreen,
.i-size-actual,
.i-shuffle,
.i-share-alt,
.i-share,
.i-rocket,
.i-question,
.i-pie-chart,
.i-pencil,
.i-note,
.i-loop,
.i-home,
.i-grid,
.i-graph,
.i-microphone,
.i-music-tone-alt,
.i-music-tone,
.i-earphones-alt,
.i-earphones,
.i-equalizer,
.i-like,
.i-dislike,
.i-control-start,
.i-control-rewind,
.i-control-play,
.i-control-pause,
.i-control-forward,
.i-control-end,
.i-volume-1,
.i-volume-2,
.i-volume-off,
.i-calendar,
.i-bulb,
.i-chart,
.i-ban,
.i-bubble,
.i-camrecorder,
.i-camera,
.i-cloud-download,
.i-cloud-upload,
.i-envelope,
.i-eye,
.i-flag,
.i-heart,
.i-info,
.i-key,
.i-link,
.i-lock,
.i-lock-open,
.i-magnifier,
.i-magnifier-add,
.i-magnifier-remove,
.i-paper-clip,
.i-paper-plane,
.i-power,
.i-refresh,
.i-reload,
.i-settings,
.i-star,
.i-symbol-female,
.i-symbol-male,
.i-target,
.i-credit-card,
.i-paypal,
.i-social-tumblr,
.i-social-twitter,
.i-social-facebook,
.i-social-instagram,
.i-social-linkedin,
.i-social-pinterest,
.i-social-github,
.i-social-google,
.i-social-reddit,
.i-social-skype,
.i-social-dribbble,
.i-social-behance,
.i-social-foursqare,
.i-social-soundcloud,
.i-social-spotify,
.i-social-stumbleupon,
.i-social-youtube,
.i-social-dropbox,
.i-social-vkontakte,
.i-social-steam {
  font-family: 'simple-line-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.i-user:before {
  content: "\e005";
}
.i-people:before {
  content: "\e001";
}
.i-user-female:before {
  content: "\e000";
}
.i-user-follow:before {
  content: "\e002";
}
.i-user-following:before {
  content: "\e003";
}
.i-user-unfollow:before {
  content: "\e004";
}
.i-login:before {
  content: "\e066";
}
.i-logout:before {
  content: "\e065";
}
.i-emotsmile:before {
  content: "\e021";
}
.i-phone:before {
  content: "\e600";
}
.i-call-end:before {
  content: "\e048";
}
.i-call-in:before {
  content: "\e047";
}
.i-call-out:before {
  content: "\e046";
}
.i-map:before {
  content: "\e033";
}
.i-location-pin:before {
  content: "\e096";
}
.i-direction:before {
  content: "\e042";
}
.i-directions:before {
  content: "\e041";
}
.i-compass:before {
  content: "\e045";
}
.i-layers:before {
  content: "\e034";
}
.i-menu:before {
  content: "\e601";
}
.i-list:before {
  content: "\e067";
}
.i-options-vertical:before {
  content: "\e602";
}
.i-options:before {
  content: "\e603";
}
.i-arrow-down:before {
  content: "\e604";
}
.i-arrow-left:before {
  content: "\e605";
}
.i-arrow-right:before {
  content: "\e606";
}
.i-arrow-up:before {
  content: "\e607";
}
.i-arrow-up-circle:before {
  content: "\e078";
}
.i-arrow-left-circle:before {
  content: "\e07a";
}
.i-arrow-right-circle:before {
  content: "\e079";
}
.i-arrow-down-circle:before {
  content: "\e07b";
}
.i-check:before {
  content: "\e080";
}
.i-clock:before {
  content: "\e081";
}
.i-plus:before {
  content: "\e095";
}
.i-minus:before {
  content: "\e615";
}
.i-close:before {
  content: "\e082";
}
.i-event:before {
  content: "\e619";
}
.i-exclamation:before {
  content: "\e617";
}
.i-organization:before {
  content: "\e616";
}
.i-trophy:before {
  content: "\e006";
}
.i-screen-smartphone:before {
  content: "\e010";
}
.i-screen-desktop:before {
  content: "\e011";
}
.i-plane:before {
  content: "\e012";
}
.i-notebook:before {
  content: "\e013";
}
.i-mustache:before {
  content: "\e014";
}
.i-mouse:before {
  content: "\e015";
}
.i-magnet:before {
  content: "\e016";
}
.i-energy:before {
  content: "\e020";
}
.i-disc:before {
  content: "\e022";
}
.i-cursor:before {
  content: "\e06e";
}
.i-cursor-move:before {
  content: "\e023";
}
.i-crop:before {
  content: "\e024";
}
.i-chemistry:before {
  content: "\e026";
}
.i-speedometer:before {
  content: "\e007";
}
.i-shield:before {
  content: "\e00e";
}
.i-screen-tablet:before {
  content: "\e00f";
}
.i-magic-wand:before {
  content: "\e017";
}
.i-hourglass:before {
  content: "\e018";
}
.i-graduation:before {
  content: "\e019";
}
.i-ghost:before {
  content: "\e01a";
}
.i-game-controller:before {
  content: "\e01b";
}
.i-fire:before {
  content: "\e01c";
}
.i-eyeglass:before {
  content: "\e01d";
}
.i-envelope-open:before {
  content: "\e01e";
}
.i-envelope-letter:before {
  content: "\e01f";
}
.i-bell:before {
  content: "\e027";
}
.i-badge:before {
  content: "\e028";
}
.i-anchor:before {
  content: "\e029";
}
.i-wallet:before {
  content: "\e02a";
}
.i-vector:before {
  content: "\e02b";
}
.i-speech:before {
  content: "\e02c";
}
.i-puzzle:before {
  content: "\e02d";
}
.i-printer:before {
  content: "\e02e";
}
.i-present:before {
  content: "\e02f";
}
.i-playlist:before {
  content: "\e030";
}
.i-pin:before {
  content: "\e031";
}
.i-picture:before {
  content: "\e032";
}
.i-handbag:before {
  content: "\e035";
}
.i-globe-alt:before {
  content: "\e036";
}
.i-globe:before {
  content: "\e037";
}
.i-folder-alt:before {
  content: "\e039";
}
.i-folder:before {
  content: "\e089";
}
.i-film:before {
  content: "\e03a";
}
.i-feed:before {
  content: "\e03b";
}
.i-drop:before {
  content: "\e03e";
}
.i-drawer:before {
  content: "\e03f";
}
.i-docs:before {
  content: "\e040";
}
.i-doc:before {
  content: "\e085";
}
.i-diamond:before {
  content: "\e043";
}
.i-cup:before {
  content: "\e044";
}
.i-calculator:before {
  content: "\e049";
}
.i-bubbles:before {
  content: "\e04a";
}
.i-briefcase:before {
  content: "\e04b";
}
.i-book-open:before {
  content: "\e04c";
}
.i-basket-loaded:before {
  content: "\e04d";
}
.i-basket:before {
  content: "\e04e";
}
.i-bag:before {
  content: "\e04f";
}
.i-action-undo:before {
  content: "\e050";
}
.i-action-redo:before {
  content: "\e051";
}
.i-wrench:before {
  content: "\e052";
}
.i-umbrella:before {
  content: "\e053";
}
.i-trash:before {
  content: "\e054";
}
.i-tag:before {
  content: "\e055";
}
.i-support:before {
  content: "\e056";
}
.i-frame:before {
  content: "\e038";
}
.i-size-fullscreen:before {
  content: "\e057";
}
.i-size-actual:before {
  content: "\e058";
}
.i-shuffle:before {
  content: "\e059";
}
.i-share-alt:before {
  content: "\e05a";
}
.i-share:before {
  content: "\e05b";
}
.i-rocket:before {
  content: "\e05c";
}
.i-question:before {
  content: "\e05d";
}
.i-pie-chart:before {
  content: "\e05e";
}
.i-pencil:before {
  content: "\e05f";
}
.i-note:before {
  content: "\e060";
}
.i-loop:before {
  content: "\e064";
}
.i-home:before {
  content: "\e069";
}
.i-grid:before {
  content: "\e06a";
}
.i-graph:before {
  content: "\e06b";
}
.i-microphone:before {
  content: "\e063";
}
.i-music-tone-alt:before {
  content: "\e061";
}
.i-music-tone:before {
  content: "\e062";
}
.i-earphones-alt:before {
  content: "\e03c";
}
.i-earphones:before {
  content: "\e03d";
}
.i-equalizer:before {
  content: "\e06c";
}
.i-like:before {
  content: "\e068";
}
.i-dislike:before {
  content: "\e06d";
}
.i-control-start:before {
  content: "\e06f";
}
.i-control-rewind:before {
  content: "\e070";
}
.i-control-play:before {
  content: "\e071";
}
.i-control-pause:before {
  content: "\e072";
}
.i-control-forward:before {
  content: "\e073";
}
.i-control-end:before {
  content: "\e074";
}
.i-volume-1:before {
  content: "\e09f";
}
.i-volume-2:before {
  content: "\e0a0";
}
.i-volume-off:before {
  content: "\e0a1";
}
.i-calendar:before {
  content: "\e075";
}
.i-bulb:before {
  content: "\e076";
}
.i-chart:before {
  content: "\e077";
}
.i-ban:before {
  content: "\e07c";
}
.i-bubble:before {
  content: "\e07d";
}
.i-camrecorder:before {
  content: "\e07e";
}
.i-camera:before {
  content: "\e07f";
}
.i-cloud-download:before {
  content: "\e083";
}
.i-cloud-upload:before {
  content: "\e084";
}
.i-envelope:before {
  content: "\e086";
}
.i-eye:before {
  content: "\e087";
}
.i-flag:before {
  content: "\e088";
}
.i-heart:before {
  content: "\e08a";
}
.i-info:before {
  content: "\e08b";
}
.i-key:before {
  content: "\e08c";
}
.i-link:before {
  content: "\e08d";
}
.i-lock:before {
  content: "\e08e";
}
.i-lock-open:before {
  content: "\e08f";
}
.i-magnifier:before {
  content: "\e090";
}
.i-magnifier-add:before {
  content: "\e091";
}
.i-magnifier-remove:before {
  content: "\e092";
}
.i-paper-clip:before {
  content: "\e093";
}
.i-paper-plane:before {
  content: "\e094";
}
.i-power:before {
  content: "\e097";
}
.i-refresh:before {
  content: "\e098";
}
.i-reload:before {
  content: "\e099";
}
.i-settings:before {
  content: "\e09a";
}
.i-star:before {
  content: "\e09b";
}
.i-symbol-female:before {
  content: "\e09c";
}
.i-symbol-male:before {
  content: "\e09d";
}
.i-target:before {
  content: "\e09e";
}
.i-credit-card:before {
  content: "\e025";
}
.i-paypal:before {
  content: "\e608";
}
.i-social-tumblr:before {
  content: "\e00a";
}
.i-social-twitter:before {
  content: "\e009";
}
.i-social-facebook:before {
  content: "\e00b";
}
.i-social-instagram:before {
  content: "\e609";
}
.i-social-linkedin:before {
  content: "\e60a";
}
.i-social-pinterest:before {
  content: "\e60b";
}
.i-social-github:before {
  content: "\e60c";
}
.i-social-google:before {
  content: "\e60d";
}
.i-social-reddit:before {
  content: "\e60e";
}
.i-social-skype:before {
  content: "\e60f";
}
.i-social-dribbble:before {
  content: "\e00d";
}
.i-social-behance:before {
  content: "\e610";
}
.i-social-foursqare:before {
  content: "\e611";
}
.i-social-soundcloud:before {
  content: "\e612";
}
.i-social-spotify:before {
  content: "\e613";
}
.i-social-stumbleupon:before {
  content: "\e614";
}
.i-social-youtube:before {
  content: "\e008";
}
.i-social-dropbox:before {
  content: "\e00c";
}
.i-social-vkontakte:before {
  content: "\e618";
}
.i-social-steam:before {
  content: "\e620";
}
