#file{
    background-color: rgb(0, 0, 0) !important;
    opacity: 1 !important;
}

.form-select{
    border:1px solid #eeeeee;
    outline: 0ch;
    box-shadow: #eeeeee;
    transition: none;
}

.form-select:focus {

    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(252, 252, 253);
    border-color: black;
}